<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">招聘模块</a>
          <i>></i>
          <a href="javascript:;" @click="pageBack" class="cur-a"
            >人才库</a
          >
          <i>></i>
          <a href="javascript:;" class="cur-a">教育经历</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div class="btnBox" style="margin-left: 20px">
              <el-button
                class="bgc-bv"
                round
                @click="addEditRecommendedSettings"
                >新增教育经历</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="80px"
                :index="indexMethod"
              />
              <el-table-column
                label="学校"
                align="left"
                prop="schoolName"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                label="学历"
                align="left"
                prop="education"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("HR_EDUCATION", scope.row.education) }}
                </template>
              </el-table-column>
              <el-table-column
                label="专业"
                align="left"
                prop="major"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="在校时间"
                align="left"
                show-overflow-tooltip
                min-width="200"
              >
                <template slot-scope="scope">
                  {{ scope.row.enrollmentYear | momentDate }} 至
                  {{ scope.row.leaveYear | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="是否统招"
                align="left"
                prop="manpowerRecruitment"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.manpowerRecruitment? "是":scope.row.manpowerRecruitment!==undefined && scope.row.manpowerRecruitment!==""?"否":"" }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="150px">
                <div slot-scope="scope">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="addEditRecommendedSettings(scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="deleteData(scope.row)"
                    >删除</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <!-- 新增教育经历 - 弹框 -->
    <el-dialog
      title="新增教育经历"
      :visible.sync="previewLoding"
      width="50%"
      top="2%"
      center
      ><div class="ovy-a">
        <el-form
          :model="previewData"
          ref="ruleForm"
          :rules="rules"
          label-width="150px"
          style="width: 100%"
          ><el-form-item label="学校名称：" prop="schoolName">
            <el-input
              v-model="previewData.schoolName"
              type="text"
              size="small"
              clearable
              placeholder="请输入学校名称"
            />
          </el-form-item>
          <el-form-item label="学历：" prop="education">
            <el-select
              clearable
              size="small"
              v-model="previewData.education"
              placeholder="请选择学历"
            >
              <el-option
                v-for="item in educationData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="专业：" prop="major">
            <el-input
              v-model="previewData.major"
              type="text"
              size="small"
              clearable
              placeholder="请输入专业"
            />
          </el-form-item>
          <el-form-item label="在校时间：" prop="esTime">
            <el-date-picker
              clearable
              size="small"
              v-model="previewData.esTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="是否统招：" prop="manpowerRecruitment">
            <el-select
              clearable
              size="small"
              v-model="previewData.manpowerRecruitment"
              placeholder="请选择是否统招"
            >
              <el-option
                v-for="item in manpowerRecruitmentData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
<!--          <el-form-item>-->
<!--            <el-button @click="cancle('ruleForm')"-->
<!--              >取消</el-button-->
<!--            >-->
<!--            <el-button-->
<!--             -->
<!--              class="bgc-bv"-->
<!--              >确定</el-button-->
<!--            >-->
<!--          </el-form-item>-->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer" style="width: 100%;text-align: right">
        <el-button @click="cancle('ruleForm')">取 消</el-button>
        <el-button type="primary" @click="addEditRecommendedSettingsOk('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "hr_recruitModule_personnelLibrary_educationalExperience",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 学历 - 下拉数据
      educationData: [],
      // 是否统招 - 下拉数据
      manpowerRecruitmentData: [
        {
          label: "是",
          value: true,
        },
        {
          label: "否",
          value: false,
        },
      ],
      // 新增教育经历 - 弹框
      previewLoding: false,
      // 新增教育经历 - 弹框 - 数据
      previewData: {
        talentId: "", // 人才id
        educationExperienceId: "", // 教育经历id
        schoolName: "", // 学校名称
        education: "", // 学历
        major: "", // 专业
        esTime: [], // 在校时间
        manpowerRecruitment: true, // 是否统招
      },
      // 新增教育经历 - 数据校验
      rules: {
        schoolName: [
          { required: true, message: "请输入学校名称", trigger: "blur" },
        ],
        // education: [
        //   {
        //     required: true,
        //     message: "请选择学历",
        //     trigger: "change",
        //   },
        // ],
        // major: [{ required: true, message: "请输入专业", trigger: "blur" }],
        esTime: [
          {
            required: true,
            message: "请选择在校时间",
            trigger: "change",
          },
        ],
        // manpowerRecruitment: [
        //   { required: true, message: "请选择是否统招", trigger: "change" },
        // ],
      },
      retrievalData:{},
      currentPage:''
    };
  },
  computed: {},
  created() {
    this.previewData.talentId = this.$route.query.talentId;
    if(this.$route.query.retrievalData){
      this.retrievalData = this.$route.query.retrievalData
    }
    if(this.$route.query.currentPage){
      this.currentPage = this.$route.query.currentPage
    }

    this.getInvoice_type();
  },
  methods: {
    // 返回上一页
    pageBack(){
      this.$router.push({
        path: "/hr/recruitModule/personnelLibrary/personnelList",
        query: {
          retrievalData: this.retrievalData,
          currentPage: this.currentPage
        },
      });
    },
    // 获取 - 获取字典
    getInvoice_type() {
      // 学历
      let arr1 = this.$setDictionary("HR_EDUCATION", "list");
      for (const key in arr1) {
        this.educationData.push({
          label: arr1[key],
          value: key,
        });
      }
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        talentId: this.$route.query.talentId,
      };
      this.doFetch({
        url: "/hr/talent/info/educationExperienceLogPage",
        params,
        pageNum,
      });
    },
    // 新增|编辑 - 教育经历 - 弹框
    addEditRecommendedSettings(item) {
      this.previewLoding = true;
      if (item.educationExperienceId) {
        // 编辑
        this.previewData.educationExperienceId = item.educationExperienceId;
        this.previewData.schoolName = item.schoolName;
        this.previewData.education = item.education;
        this.previewData.major = item.major;
        if(item.enrollmentYear&&item.leaveYear){
            this.previewData.esTime = [
                item.enrollmentYear.replace(/\//g, "-"),
                item.leaveYear.replace(/\//g, "-"),
            ];
        }else{
            this.previewData.esTime=null;
        }
        this.previewData.manpowerRecruitment = item.manpowerRecruitment;
      } else {
        // 新增
        Object.keys(this.previewData).forEach(
          (key) => (this.previewData[key] = "")
        );
        this.previewData.talentId = this.$route.query.talentId;
      }
    },
    // 新增|编辑 - 教育经历 - 弹框 - 确定
    addEditRecommendedSettingsOk(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = {
            ...this.previewData,
          };
          if (this.previewData.esTime) {
            params.enrollmentYear = this.previewData.esTime[0];
            params.leaveYear = this.previewData.esTime[1];
          }
          this.$post("/hr/talent/info/saveOrUpdateEducationExperience", params)
            .then((res) => {
              this.previewLoding = false;
              this.$refs[formName].resetFields();
              this.getData();
              this.$message({
                message: res.message,
                type: "success",
              });
            })
            .catch(() => {
              return;
            });
        }
      });
    },
    // 新增|编辑 - 教育经历 - 弹框 - 取消
    cancle(formName) {
      this.$refs[formName].resetFields();
      this.previewLoding = false;
    },
    // 删除
    deleteData(item) {
      let str = '教育经历'
      this.$confirm(
          "是否删除此条" + str,
          "提示",
          {
            confirmButtonText: "确定删除",
            cancelButtonText: "取消",
            type: "warning",
          }
      )
          .then(() => {
            this.$post("/hr/talent/info/removeEducationExperienceLog", {
              educationExperienceId: item.educationExperienceId
            })
                .then((res) => {
                  this.getData();
                  this.$message({
                    message: res.message,
                    type: "success",
                  });
                })
                .catch(() => {
                  return;
                });
          })
          .catch(() => {
            return;
          });

    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {},
};
</script>
<style lang="less" scoped>
</style>
